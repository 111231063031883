<template>
  <b-overlay :show="isLoading">
    <form @submit.prevent="createLine()">
      <div class="mb-3">


        <div class="form-group">
          <label>libelle </label>
          <input v-model="form.libelle" :class="errors.libelle?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.libelle" class="invalid-feedback">
            <template v-for=" error in errors.libelle"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>description </label>
          <input v-model="form.description"
                 :class="errors.description?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.description" class="invalid-feedback">
            <template v-for=" error in errors.description"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>valide_one </label>
          <input v-model="form.valide_one" :class="errors.valide_one?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.valide_one" class="invalid-feedback">
            <template v-for=" error in errors.valide_one"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>valide_two </label>
          <input v-model="form.valide_two" :class="errors.valide_two?'form-control is-invalid':'form-control'"
                 type="text">

          <div v-if="errors.valide_two" class="invalid-feedback">
            <template v-for=" error in errors.valide_two"> {{ error[0] }}</template>

          </div>
        </div>


        <div class="form-group">
          <label>works </label>
          <CustomSelect
              :key="form.work"
              :columnDefs="['libelle']"
              :oldValue="form.work"
              :renderCallBack="(data)=>`${data.Selectlabel}`"
              :selectCallBack="(data)=>form.work_id=data.id"
              :url="`${axios.defaults.baseURL}/api/works-Aggrid1`"
              filter-key=""
              filter-value=""
          />
          <div v-if="errors.work_id" class="invalid-feedback">
            <template v-for=" error in errors.work_id"> {{ error[0] }}</template>

          </div>
        </div>

      </div>

      <button class="btn btn-primary" type="submit">
        <i class="fas fa-floppy-disk"></i> Créer
      </button>
    </form>
  </b-overlay>
</template>

<script>


import Files from "@/components/Files.vue"


export default {
  name: 'CreateProcessus',
  components: {VSelect: () => import("vue-select"), CustomSelect: () => import("@/components/CustomSelect.vue"), Files},
  props: [
    'gridApi',
    'modalFormId',
    'worksData',
  ],
  data() {
    return {
      errors: [],
      isLoading: false,
      form: {

        id: "",

        libelle: "",

        description: "",

        valide_one: "",

        valide_two: "",

        work_id: "",

        created_at: "",

        updated_at: "",
      }
    }
  },
  methods: {
    createLine() {
      this.isLoading = true
      this.axios.post('/api/processus', this.form).then(response => {
        this.isLoading = false
        this.resetForm()
        this.gridApi.applyServerSideTransaction({
          add: [
            response.data
          ],
        });
        this.gridApi.refreshServerSide()
        this.$bvModal.hide(this.modalFormId)
        this.$toast.success('Operation effectuer avec succes')
        this.$emit('close')
        console.log(response.data)
      }).catch(error => {
        this.errors = error.response.data.errors
        this.isLoading = false
        this.$toast.error('Erreur survenue lors de l\'enregistrement')
      })
    },
    resetForm() {
      this.form = {
        id: "",
        libelle: "",
        description: "",
        valide_one: "",
        valide_two: "",
        work_id: "",
        created_at: "",
        updated_at: "",
      }
    }
  }
}
</script>
